'use client'

import * as React from 'react'
import { signIn } from 'next-auth/react'

import { cn } from '@/lib/utils'
import { Button, type ButtonProps } from '@/components/ui/button'
import { IconGitHub, IconGoogleColour, IconSpinner } from '@/components/ui/icons'
import { IconBrandGoogle, IconKey, IconLock, IconLockAccess, IconPassword, IconUserCircle } from '@tabler/icons-react'
import { useRouter } from 'next/navigation'

interface LoginButtonProps extends ButtonProps {
  showGoogleIcon?: boolean
  text?: string
}

export function LoginButton({
  text = 'Login with Google',
  showGoogleIcon = true,
  className,
  ...props
}: LoginButtonProps) {
  const router = useRouter();
  const [isLoading, setIsLoading] = React.useState(false)
  return (
    <button
      onClick={async () => router.push('/login')}
      className={`
          flex font-semibold items-center gap-1 justify-center w-full h-12 rounded-md
          border border-neutral-300 px-2 lg:px-3 shadow-md text-md
          !text-neutral-900
      `}
    >
      <span className="hidden md:block text-sm">Login or Register</span>
      <span className="md:hidden">Login</span>
    </button>
  )
}