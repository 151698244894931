"use client";

import React from "react";
import * as Separator from "@radix-ui/react-separator";
import Link from "next/link";

const CompanyInfo = () => (
  <div className="w-full max-w-[300px] mx-[15px] text-neutral-800">
    <div className="text-[15px] leading-5 font-medium">
      Let&apos;s make politics more transparent.
    </div>
    <Separator.Root className="bg-neutral-600 data-[orientation=horizontal]:h-px data-[orientation=horizontal]:w-full data-[orientation=vertical]:h-full data-[orientation=vertical]:w-px my-[15px]" />
    <div className="flex h-5 items-center">
      <Link href="/blog">
        <div className="text-[15px] leading-5">Blog</div>
      </Link>
      <Separator.Root
        className="bg-neutral-600 data-[orientation=horizontal]:h-px data-[orientation=horizontal]:w-full data-[orientation=vertical]:h-full data-[orientation=vertical]:w-px mx-[15px]"
        decorative
        orientation="vertical"
      />
      <Link href="mailto:contact@brightpol.uk">
        <div className="text-[15px] leading-5">Contact</div>
      </Link>
      <Separator.Root
        className="bg-neutral-600 data-[orientation=horizontal]:h-px data-[orientation=horizontal]:w-full data-[orientation=vertical]:h-full data-[orientation=vertical]:w-px mx-[15px]"
        decorative
        orientation="vertical"
      />
      <Link href="/about">
        <div className="text-[15px] leading-5">Mission</div>
      </Link>
      <Separator.Root
        className="bg-neutral-600 data-[orientation=horizontal]:h-px data-[orientation=horizontal]:w-full data-[orientation=vertical]:h-full data-[orientation=vertical]:w-px mx-[15px]"
        decorative
        orientation="vertical"
      />
      <Link href="/search">
        <div className="text-[15px] leading-5">Search</div>
      </Link>
    </div>
  </div>
);

export default CompanyInfo;
