import { ClassValue, clsx } from "clsx";
import { customAlphabet } from 'nanoid'
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const nanoid = customAlphabet(
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
  7
);

export const getAccentColourForParty = (party: any) => {
  const abbrLowercase = party?.abbreviation?.toLowerCase().trim();

  var light;
  var dark;

  switch (abbrLowercase) {
      case 'con':
          light = 'bg-[#0588F0]';
          dark = 'bg-blue-700';
          break;
      case 'lab':
          light = 'bg-red-600';
          dark = 'bg-red-700';
          break;
      case 'ind':
          light = 'bg-[#8B8D98]';
          break;
      default:
          light = 'bg-neutral-400';
          dark = 'bg-neutral-600';
          break;
  }

  return [light, dark];
};

export const getBgColourForParty = (party: any) => {
  const abbrLowercase = party?.abbreviation?.toLowerCase().trim();

  var light;
  var dark;

  switch (abbrLowercase) {
      case 'con':
          light = '#0D74CE';
          dark = '#0D74CE';
          break;
      case 'lab':
          light = '#CE2C31';
          dark = '#CE2C31';
          break;
      case 'ind':
          light = '#8B8D98';
          dark = '#8B8D98';
          break;
      default:
          light = '#60646C';
          dark = '#181818';
          break;
  }

  return [light, dark];
};

export const getFgColourForParty = (party: any) => {
  const abbrLowercase = party?.abbreviation?.toLowerCase();

  var light;
  var dark;

  switch (abbrLowercase) {
      default:
          light = 'text-neutral-100';
          dark = 'text-neutral-200';
  }

  return [light, dark];
};

export const calculateConfidencePercentage = (score: number, maxScore: number) => {
  // Ensure maxScore is not zero to avoid division by zero
  if (maxScore === 0) {
    console.error('Max score cannot be zero.');
    return 0;
  }

  // Calculate the score as a percentage of the maxScore
  const percentage = (score / maxScore) * 100;

  // Round the result to make it user-friendly
  return Math.round(percentage);
};