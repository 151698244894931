"use client";

import { useTheme } from "next-themes";
import Image from "next/image";

export default function Logo() {
  const theme = useTheme();

  return (
    <div className="flex flex-row items-center gap-2">
      {theme.theme === "dark" ? (
        <Image
          className="w-36"
          src="/img/logo-white.svg"
          alt="Next Chat"
          width={192}
          height={41}
        />
      ) : (
        <Image
          className="w-36"
          src="/img/logo-black.svg"
          alt="Next Chat"
          width={192}
          height={41}
        />
      )}
      <span className="text-white font-black py-[calc(0.15rem)] text-sm px-2 items-center flex flex-row bg-accent rounded-lg">
        beta
      </span>
    </div>
  );
}
