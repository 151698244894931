import { createPortalSession } from "@/app/actions/billing";
import { auth } from "@/auth";
import { stripe } from "@/lib/stripe/config";

const BillingLink = () => {
    return (
        <button className="inline-flex items-center justify-between w-full text-xs" onClick={async () => {
            await createPortalSession();
        }}>
            Billing
        </button>
    )
}

export default BillingLink;